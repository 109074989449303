import React, { useState, useEffect } from 'react'
import cats_1 from './cats_1.png'
import { useConnect, useAccount, useContractWrite, useContractRead } from 'wagmi'
import metamaskLogo from './metamask.png'
import walletConnectLogo from './walletconnect.svg'
import { utils, BigNumber, ethers } from 'ethers'
import swal from 'sweetalert2'

import yokittiesABI from './ABI'
import { parseEther } from 'ethers/lib/utils'

let lastHash: any = undefined

const contractAddress = '0xC303Ff7a410fB8cD27c203753088009630e2DDd4'

const MintView = () => {
    return(
        <div className="mint">
            <h2 style={{marginBottom: "5px"}}>ADOPT SOME KITTIES!</h2>
            <h3 style={{margin: 0}}>0.005 ETH PER KITTY</h3>

            <ConnectWallet />

            <h3 style={{marginBottom: "5px"}}>Or Buy on secondary?</h3>
            <div>
                <a href="https://opensea.io/collection/yo-kitties" className="wallet-connect" target="_new" style={{textDecoration: "none"}}>OpenSea</a>
                <a href="https://looksrare.org/collections/0xC303Ff7a410fB8cD27c203753088009630e2DDd4" className="wallet-connect" style={{textDecoration: "none"}} target="_new">Looksrare</a>
            </div>

            <img src={cats_1} style={{maxWidth:"66%", marginTop:"20px" }} />
        </div>
    )
}

const ConnectWallet = () => {
    const [{ data: connectData, error: connectError }, connect] = useConnect()
    const [{ data: accountData }, disconnect] = useAccount()

    if(!connectData.connected) {
        return (
            <div>
                <h3 style={{marginBottom: 0}}>CONNECT WALLET</h3>
                {connectData.connectors.map((connector) => (
                    <button
                        className="wallet-connect"
                        style={{cursor: "pointer"}}
                        disabled={!connector.ready}
                        key={connector.id}
                        onClick={() => connect(connector)}>
                    {connector.name}
                    {!connector.ready && ' (unsupported)'}
                    </button>
                ))}

                {connectError && <div>{connectError?.message ?? 'Failed to connect'}</div>}
            </div>
        )
    } else {
        return (
            <div>
                <AdoptCats />
                <br />
                <button style={{cursor: "pointer", fontSize: "80%"}} className="wallet-connect" onClick={() => disconnect()}>DISCONNECT WALLET</button>
            </div>
        )
    }
}

const AdoptCats = () => {
    const [qty, setQty] = useState(1)

    const [{ data, error, loading }, writeMint] = useContractWrite(
        {
            addressOrName: contractAddress,
            contractInterface: yokittiesABI,
        },
        'mintCats',
        {
            args: [BigNumber.from(qty), {value: parseEther("0.005").mul(qty)}]
        }
    )

    useEffect(() => {
        if(error && error.toString().indexOf("insufficient funds for intrinsic transaction cost") > -1) {
            swal.fire("Error", "Insufficient funds!", 'error')
        }
    }, [error])

    const mint = () => {
        writeMint()
    }

    if (data && data.hash !== lastHash) {
        lastHash = data.hash
        swal.fire("Congratulations!", "Your kitty purchase is on the way! It should be visible on sites like OpenSea in a few minutes.", 'success')
    }

    const updateQty = (value: any) => {
        console.log(value)

        if(value.trim() === "") {
            setQty(0)
            return
        }

        if(isNaN(value)) {
            setQty(1)
            return
        }

        if(value < 0) {
            setQty(1)
            return
        }

        if(value > 50) {
            setQty(50)
            return
        }

        setQty(value)
    }

    return(
        <div>
            <KittyCounter />

            <button disabled={qty <= 0} style={{cursor: "pointer", width: "45px"}} className="wallet-connect" onClick={() => updateQty((Number(qty)-1).toString())}>-</button>
            <input type="number" min={1} max={50} value={qty} onChange={(e) => updateQty(e.target.value)} />
            <button disabled={qty >= 25} style={{cursor: "pointer", width: "45px"}} className="wallet-connect" onClick={() => updateQty((Number(qty)+1).toString())}>+</button>

            <br />
            <button style={{cursor: "pointer"}} className="wallet-connect" onClick={() => mint()}>ADOPT KITTIES</button>
        </div>
    )
}

const KittyCounter = () => {
    const [{ data, error, loading }, read] = useContractRead(
        {
          addressOrName: contractAddress,
          contractInterface: yokittiesABI,
        },
        'totalSupply',
    )

    useEffect(() => { read() }, [])

    const minted = data?.toString() ?? "0"

    return <h3>{minted}/5000 KITTIES ADOPTED</h3>
}

export default MintView