import React from 'react'
import MintView from './MintView'

import logo from './logo.png'
import kitty_mandy from './kitty-mandy.png'
import kitty_thorne from './kitty-thorne.png'
import kitty_cat from './kitty-cat.png'

import cats_1 from './cats_1.png'
import cats_2 from './cats_2.png'

import { getDefaultProvider } from 'ethers'

import { Provider, chain, defaultChains } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

import { SocialIcon } from 'react-social-icons'

import './App.css'

if (document.location.protocol !== "https:" && document.location.href.indexOf("localhost") == -1){
    document.location.replace(window.location.href.replace("http:", "https:"));
}

const infura = "b9c9aee688ef45e2a99139691b233b04"
const etherscan = "AUV2TQWPJEU1Q9YS2RPWGHKNHPDRZV8IZ8"
const alchemy = "Hh8fdQZL2nYcS0fYVKslLvSoIZM7Ml9L"

const provider = getDefaultProvider(1, {
    infura: infura,
    etherscan: etherscan,
    alchemy: alchemy
})

// Chains for connectors to support
const chains = defaultChains

// Set up connectors
const connectors = () => {
  return [
    new InjectedConnector({
      chains,
      options: { shimDisconnect: true },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        infuraId: infura,
        qrcode: true,
      },
    })
  ]
}

const App = () => {
    return (
        <Provider autoConnect connectors={connectors} provider={provider}>
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                    <div className="text-block">
                        Welcome to Yo Kitties, a collaboration between smol farm and Iron Chef Cat Cora. 
                        We are raising funds benefitting Ukraine and other smol farms with Chefs for Humanity.
                    </div>

                    <br />

                    <div>
                        <a href="https://twitter.com/YoKittiesNFT" className="social-link" target="_new"><SocialIcon fgColor="#FFF" network="twitter" /> Twitter</a>
                        <a href="https://discord.gg/6nzuDZ3aT6" className="social-link" target="_new"><SocialIcon fgColor="#FFF" network="discord" /> Discord</a>
                    </div>

                </header>

                <MintView />
                

                <div className="team">
                    <h2>CREATORS</h2>

                    <div className="team-row">
                        <div className="team-member">
                            <img src={kitty_mandy} className="team-cat" alt="Fumeiji's Cat" />
                            <h3>FUMEIJI.eth</h3>
                            <SocialIcon fgColor="#FFF" url="https://twitter.com/fumeiji" />
                        </div>

                        <div className="team-member">
                            <img src={kitty_thorne} className="team-cat" alt="Thorne's Cat" />
                            <h3>existentialenso.eth</h3>
                            <SocialIcon fgColor="#FFF" url="https://twitter.com/existentialenso" />
                            &nbsp;
                            <SocialIcon fgColor="#FFF" url="https://github.com/existentialenso" />
                        </div>

                        <div className="team-member">
                            <img src={kitty_cat} className="team-cat" alt="Cats's Cat" />
                            <h3>catcora.eth</h3>
                            <SocialIcon fgColor="#FFF" url="https://instagram.com/catcora" />
                            &nbsp;
                            <SocialIcon fgColor="#FFF" url="https://twitter.com/catcora" />
                        </div>
                    </div>
                </div>

                <div className="roadmap">
                    <h2>ROADMAP</h2>

                    <h3>PHASE ONE</h3>

                    <div className="text-block">
                        A maximuum of 5,000 cats are available for adoption on this site.
                    </div>

                    <h3>PHASE TWO</h3>

                    <div className="text-block">
                        Portions of the proceeds will be donated to Ukraine and other smol farms in need. This is in 
                        partnership with Chefs for Humanity.
                    </div>

                    <h3>AND BEYOND</h3>

                    <div className="text-block">
                        Much like with the Dastardly Ducks, we want to see this project grow and evolve. 
                        We want to make a lot more fun stuff for people set in the world of Yo Kitties 
                        and Dastardly Ducks.
                    </div>

                    <img src={cats_2} style={{maxWidth:"66%", marginTop:"20px" }} />
                </div>

                <footer>
                    <h3>MORE</h3>
                    <a href="https://smol.farm" target="_new">Smol Farm</a>
                    <a href="https://www.catcora.com" target="_new">Cat Cora</a>
                    <a href="https://etherscan.io/address/0xC303Ff7a410fB8cD27c203753088009630e2DDd4" target="_blank">Smart Contract</a>
                </footer>
            </div>
        </Provider>
    )
}

export default App
